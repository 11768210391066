import "./App.css";

function App() {
  const openYoutubeVideo = () => {
    const youtubeUrl = "https://youtu.be/WplYQGGvcgg?feature=shared";
    window.open(youtubeUrl, "_blank");
  };
  return (
    <div className="App">
      <header className="App-header">
        <h1 className="y2k-title">Welcome to the Figo</h1>
        <p className="y2k-text">Drop into risky reels</p>
        <button className="y2k-button" onClick={openYoutubeVideo}>
          😈
        </button>
      </header>
    </div>
  );
}

export default App;
